function logout (router) {
  localStorage.clear()
  window.location.href = `${router.history._startLocation}`
}

export default (axios, store, router) => {
  return axios.interceptors.response.use(response => {
    if ([401, 400, 403, 426].indexOf(response.status) > -1 || response.data.error === 'invalid_token') {
      store.commit('updateUserToken', null)
      store.commit('updateUser', null)
      store.commit('setSelectedWorkspace', null)
      logout(router)
    }
    return response
  }, (error) => {
    if (error.response && error.response.config.url.includes('v1/auth-me?email=')) return Promise.reject(error)
    if (error.response && (error.response.data.msg === 'invalid user' || error.response.data.msg === 'Forbidden, token not found' || error.response.data.error === 'Invitation error')) return Promise.reject(error)
    const errorsUserInvites = ['profileCompanyExists', 'expiredInvite', 'userExists', 'userAlreadyAccepted', 'activeEmailAlreadyExists', 'userDisabled', 'This email already in use', 'You must enter a valid email address', 'email must be unique']
    const errorUserEmailInvite = ['Invalid email', 'Invalid domain', 'Disposable addresses not allowed']
    const errorPayments = ['Stripe error']
    const errorLogin = ['User is not authorized']
    const errorDepartment = ['departmentExists']
    const errorDependent = ['dependentExists']
    const deleteAccountInvalidPassword = ['invalid password']
    const errorContents = ['Provided user must have permission to perform this action']
    const errorIntegration = ['Error validating credentials']

    if (error.response && (([401].indexOf(error.response.status) > -1 && deleteAccountInvalidPassword.includes(error.response.data.msg)) || errorUserEmailInvite.includes(error.response.data.msg))) {
      return Promise.reject(error)
    }

    if (error.response && (([401, 400, 403, 426].indexOf(error.response.status) > -1 || error.response.data.error === 'invalid_token') && !errorsUserInvites.includes(error.response.data.error) && !errorsUserInvites.includes(error.response.data.msg) && !errorPayments.includes(error.response.data.msg) && !(Array.isArray(error.response.data) && errorsUserInvites.includes(error.response.data[0].msg)) && !errorLogin.includes(error.response.data.msg) && !errorDepartment.includes(error.response.data.error) && !errorDependent.includes(error.response.data.error) && !errorContents.includes(error.response.data.message) && !errorIntegration.includes(error.response.data.msg))) {
      store.commit('updateUserToken', null)
      store.commit('updateUser', null)
      store.commit('setSelectedWorkspace', null)
      logout(router)
    }

    if (error && error.response && error.response.status === 401 && !errorLogin.includes(error.response.data.msg) && !errorIntegration.includes(error.response.data.msg)) {
      store.commit('updateUserToken', null)
      store.commit('updateUser', null)
      store.commit('setSelectedWorkspace', null)
      logout(router)
    } else {
      return Promise.reject(error)
    }
    return Promise.reject(error)
  })
}
